import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../environments/environment';
import {Client} from '../../api/model/client';
import {DroitMenu, MenuItem} from '../classes/menu/menu-item';
import {CoreServiceModule} from '../core-service.module';
import {AccesService, EnumFonctionnalite} from './acces.service';
import {UserService} from './user.service';
import TypeAbonnementEnum = Client.TypeAbonnementEnum;

@Injectable({
  providedIn: CoreServiceModule,
})
export class MenuService {
  /** Le menu à afficher. */
  menu: MenuItem[];
  /** la conf menu. */
  private confMenu: MenuItem[];

  constructor(private readonly userService: UserService, private readonly logger: NGXLogger, private readonly accesService: AccesService) {
    this.initConf();
    this.userService.client$.subscribe((client: Client) => {
      this.initMenu(client);
    });
  }

  /**
   * Rechargement du menu
   */
  public rechargeMenu() {
    this.initMenu(this.userService.clientActif());
  }

  /**
   * Met le menu à jour avec le profil client.
   * @param client le client sélectionné
   */
  private initMenu(client: Client) {
    if (!client) {
      this.menu = [];
      return;
    }

    const res: MenuItem[] = [];
    for (const item of this.confMenu) {
      const entree = this.traiteMenu(client, item, false);
      if (entree) {
        res.push(entree);
      }
    }
    this.menu = res;
    this.logger.debug('menu', this.menu);
  }

  /** traite un menu. */
  private traiteMenu(client: Client, item: MenuItem, sousMenu: boolean): MenuItem {
    // blocage en cas du durée KYC restante à 0)
    if (this.userService.user.etatCompletude.nbJoursRestant !== 0 || item.libelle === 'Accueil' ||
      item.libelle === 'Dossier client' || sousMenu) {

      if (item.hasAbonnement(client.typeAbonnement) && item.hasDroit(this.userService.user)) {
        this.logger.debug(item.libelle, ' hasAbonnement ', client.typeAbonnement);
        if (item.sousMenus) {

          const res: MenuItem[] = [];
          for (const sousMenu of item.sousMenus) {
            const entree = this.traiteMenu(client, sousMenu, true);
            if (entree) {
              res.push(entree);
            }
          }

          if (res.length === 0) {
            return MenuItem.createItem(item.libelle, item.abonnementsAutorises, item.url, item.droitsMenu, item.externalUrl, item.action);
          } else {
            return new MenuItem(item.libelle, item.abonnementsAutorises, item.url, res, item.droitsMenu, item.externalUrl, item.action);
          }
        } else {
          return MenuItem.createItem(item.libelle, item.abonnementsAutorises, item.url, item.droitsMenu, item.externalUrl, item.action);
        }
      }
    }
  }

  /**
   * Initialise le menu.
   */
  private initConf() {
    this.confMenu = [
      MenuItem.createItem('Accueil',
        this.accesService.access(EnumFonctionnalite.SYNTHESE), '/'),
      MenuItem.createSousItem('Comptes', this.accesService.access(EnumFonctionnalite.LISTE_COMPTE),
        [MenuItem.createSousItemSansTitre([TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], [MenuItem.createItem('Liste de mes comptes',
          this.accesService.access(EnumFonctionnalite.LISTE_COMPTE), '/comptes/liste'),
          MenuItem.createItem('Consulter RIB / IBAN',
            this.accesService.access(EnumFonctionnalite.RIB), '/comptes/rib'),
          MenuItem.createItem('Suivre mon budget',
            this.accesService.access(EnumFonctionnalite.BUDGET), '/budget'),
          MenuItem.createItem('Paramétrer mes alertes',
            this.accesService.access(EnumFonctionnalite.PARAM_ALERTES), '/alerte/mes-alertes')])]),

      new MenuItem('Opérations',
        [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], '',
        [MenuItem.createSousItem('Mes bénéficiaires',
          this.accesService.access(EnumFonctionnalite.BENEFICIAIRE), [
            MenuItem.createItem('Liste de mes bénéficiaires', this.accesService.access(EnumFonctionnalite.BENEFICIAIRE), '/virements/beneficiaires'),
            MenuItem.createItem('Ajouter un bénéficiaire',
              this.accesService.access(EnumFonctionnalite.BENEFICIAIRE), '/virements/beneficiaires/ajout', [DroitMenu.Saisisseur, DroitMenu.Complet]),
            MenuItem.createItem('Ajouter une liste de bénéficiaires',
              this.accesService.access(EnumFonctionnalite.MULTIBENEFICIAIRE), '/virements/multi-beneficiaires/ajout', [DroitMenu.Saisisseur, DroitMenu.Complet])]),
          MenuItem.createSousItem('Mes virements', this.accesService.access(EnumFonctionnalite.VIREMENT), [
            MenuItem.createItem('Compte à compte', this.accesService.access(EnumFonctionnalite.VIREMENT), '/virements/ordre/Compte'),
            MenuItem.createItem('Local, France, OM', this.accesService.access(EnumFonctionnalite.VIREMENT), '/virements/ordre/PF-FR-OM'),
            MenuItem.createItem('International', this.accesService.access(EnumFonctionnalite.VIREMENT_INTERNATIONAL), '/virements/ordre/Int'),
            MenuItem.createItem('Multi bénéficiaire', this.accesService.access(EnumFonctionnalite.MULTIBENEFICIAIRE), '/virements/multi-beneficiaires'),
            MenuItem.createItem('Liste de mes virements permanents', this.accesService.access(EnumFonctionnalite.VIREMENT_PERMANENT), '/virements/permanents'),
            MenuItem.createItem('Liste de mes virements', this.accesService.access(EnumFonctionnalite.VIREMENT), '/virements')]),
          MenuItem.createSousItem('Dépôts de fichiers', this.accesService.access(EnumFonctionnalite.DEPOT_FICHIER), [
            MenuItem.createItem('Mes transferts de fichiers', this.accesService.access(EnumFonctionnalite.DEPOT_FICHIER), '/virements/depot-fichier/liste')])]),

      MenuItem.createSousItem('Moyens de paiement', this.accesService.access(EnumFonctionnalite.MOYEN_PAIEMENT),
        [MenuItem.createSousItemSansTitre(this.accesService.access(EnumFonctionnalite.MOYEN_PAIEMENT), [
          MenuItem.createItem('Liste de mes cartes', this.accesService.access(EnumFonctionnalite.MOYEN_PAIEMENT_CARTES), '/cartes/liste'),
          MenuItem.createItem('Commander des chéquiers', this.accesService.access(EnumFonctionnalite.MOYEN_PAIEMENT_CHEQUES), '/chequier/liste'),
          MenuItem.createItem('Faire une opposition sur chèque(s)', this.accesService.access(EnumFonctionnalite.MOYEN_PAIEMENT_CHEQUES_OPPOSITION), '/chequier/opposition', [DroitMenu.Complet]),
          MenuItem.createItem('Paiement en ligne', this.accesService.access(EnumFonctionnalite.MOYEN_PAIEMENT_PAIEMENT_EN_LIGNE), '/paiement-en-ligne', [DroitMenu.ClientPersonnePhysique]),
        ])]),

      MenuItem.createSousItem('Relevés électroniques', this.accesService.access(EnumFonctionnalite.RELEVES), [
        MenuItem.createSousItemSansTitre(this.accesService.access(EnumFonctionnalite.RELEVES), [
          MenuItem.createItem('Consulter mes relevés', this.accesService.access(EnumFonctionnalite.RELEVES), '/releves/liste'),
          MenuItem.createItem('Paramètres', this.accesService.access(EnumFonctionnalite.PARAM_RELEVES), '/releves/parametre', [DroitMenu.Complet]),
          MenuItem.createItem('Partage des données', this.accesService.access(EnumFonctionnalite.PARTAGE_DONNEES), '/releves/partage-donnees', [DroitMenu.EntrepriseIndividuelle]),
        ])]),];

    if (environment.creditDemat) {
      this.confMenu.push(
        MenuItem.createSousItem('Crédit en ligne', this.accesService.access(EnumFonctionnalite.CREDITS), [
            MenuItem.createSousItemSansTitre(this.accesService.access(EnumFonctionnalite.CREDITS), [
                MenuItem.createItem('Faire une demande', this.accesService.access(EnumFonctionnalite.CREDITS), '/demande-credit/demande', [DroitMenu.Complet]),
                MenuItem.createItem('Consulter mes demandes', this.accesService.access(EnumFonctionnalite.CREDITS), '/demande-credit/liste', [DroitMenu.Complet]),
              ]
            )
          ], [DroitMenu.Credit]
        )
      );
    }

    this.confMenu.push(
      MenuItem.createSousItem('Dossier client', this.accesService.access(EnumFonctionnalite.DOSSIER_CLIENT), [
          MenuItem.createSousItemSansTitre(this.accesService.access(EnumFonctionnalite.DOSSIER_CLIENT), [
              MenuItem.createItem('Mon dossier client', this.accesService.access(EnumFonctionnalite.DOSSIER_CLIENT), '/kyc/dossier-client', [DroitMenu.ClientPersonnePhysique, DroitMenu.EntrepriseIndividuelle]),
            ]
          )
        ], [DroitMenu.ClientPersonnePhysique, DroitMenu.EntrepriseIndividuelle]
      )
    );

    this.confMenu.push(MenuItem.createItem('Délégation', this.accesService.access(EnumFonctionnalite.DELEGATION), '/delegations', [DroitMenu.Complet]));

    this.confMenu.push(MenuItem.createSousItem('Nos produits',
      [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], [
        MenuItem.createSousItemSansTitre([TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], [
          MenuItem.createItem('Cartes bancaires', [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], environment.urlsInstitutionelles.cartes, undefined, true),
          MenuItem.createItem('Crédits', [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], environment.urlsInstitutionelles.credits, undefined, true),
          MenuItem.createItem('Assurances', [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], environment.urlsInstitutionelles.assurances, undefined, true),
          MenuItem.createItem('Offres groupées', [TypeAbonnementEnum.Base, TypeAbonnementEnum.BaseGratuit, TypeAbonnementEnum.Free, TypeAbonnementEnum.Premium, TypeAbonnementEnum.Uru, TypeAbonnementEnum.Light], environment.urlsInstitutionelles.offres_groupees, undefined, true),
        ])]));
  }

}
